<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Equipment">
      <!-- <button
        @click="showingUploadDocumentPanel = true"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M17 8l-5-5-5 5M12 3v12"
          />
        </svg>
        <span>Upload Document</span>
      </button> -->
    </page-header>
    <main class="p-6 flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div
          v-if="viewState == 'Loading'"
          :class="
            `absolute inset-0 flex bg-white z-50 bg-opacity-75 rounded justify-center py-20 ${
              equipment.length > 0 ? '' : 'items-center'
            }`
          "
        >
          <spinner :color="'text-black'" :size="10" />
        </div>
        <div class="flex items-center justify-between">
          <div class="flex space-x-2">
            <button
              @click="viewAllTab()"
              class="px-4 py-2 rounded transition-colors duration-300 inline-flex items-center font-semibold"
              :class="
                currentTab == 'all'
                  ? 'bg-orange-300 text-orange-900'
                  : 'hover:bg-gray-200'
              "
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01" />
              </svg>
              <span>All Equipment</span>
            </button>

            <button
              @click="currentTab = 'search'"
              class="px-4 py-2 rounded transition-colors duration-300 inline-flex items-center font-semibold"
              :class="
                currentTab == 'search'
                  ? 'bg-orange-300 text-orange-900'
                  : 'hover:bg-gray-200'
              "
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
              <span>Search</span>
            </button>
          </div>
          <form
            @submit.prevent="submitSearchForm"
            class="mb-4 flex lg:ml-10 w-full md:w-1/2 lg:w-1/3"
          >
            <input
              type="text"
              class="w-full rounded-l bge-input bge-input-spacing"
              placeholder="Search Name, Product Code or Serial Number"
              v-model="searchTerm"
            />
            <!-- <div class="relative">
            <select
              class="bge-input bge-select rounded-none h-full"
              v-model="categoryFilter"
              @change="onCategoryFilterChanged"
            >
              <option value="">No Category</option>
              <optgroup label="Categories">
                <option>Brochures</option>
                <option>Certifications</option>
                <option>Help &amp; Training</option>
                <option>Miscellaneous</option>
                <option>Product Manual</option>
                <option>Sample Plans</option>
                <option>Schematics</option>
                <option>Software</option>
                <option>Specifications</option>
              </optgroup>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div> -->
            <button
              type="submit"
              class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
            >
              <svg
                class="stroke-current h-5 w-5"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </button>
          </form>
        </div>
        <div :class="currentTab == 'all' ? 'block' : 'hidden'">
          <Table
            key="all"
            :columns="columns"
            :data="equipment"
            :totalRecords="totalRecords"
            :hasPagination="true"
            :currentPage="paginationOptions.currentPage"
            :totalPages="paginationOptions.totalPages"
            :initialSortOptions="sortOptions"
            noDataMessage="There are no equipment items to show."
            @onSortChanged="onTableSortChanged"
            @onPageChanged="onTablePageChanged"
            @onRowClicked="onRowClicked"
            @onFilterChanged="onFilterChanged"
          >
            <template v-slot:isLoanUnit="props">
              <svg
                :class="
                  `stroke-current h-5 w-5 ${
                    props.row.is_loan_unit ? 'text-green-700' : 'text-red-700'
                  }`
                "
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
              >
                <path v-if="props.row.is_loan_unit" d="M20 6L9 17l-5-5" />
                <path v-else d="M18 6 6 18M6 6l12 12" />
              </svg>
            </template>
            <template v-slot:isOperational="props">
              <svg
                :class="
                  `stroke-current h-5 w-5 ${
                    props.row.is_operational ? 'text-green-700' : 'text-red-700'
                  }`
                "
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
              >
                <path v-if="props.row.is_operational" d="M20 6L9 17l-5-5" />
                <path v-else d="M18 6 6 18M6 6l12 12" />
              </svg>
            </template>
          </Table>
        </div>
        <div :class="currentTab == 'search' ? 'block' : 'hidden'">
          <h4
            class="ml-3 mt-3 mb-5"
            v-if="lastSearchTerm && searchResults.length > 0"
          >
            {{ totalSearchRecords }} search results for '{{ lastSearchTerm }}'
          </h4>
          <Table
            :key="`search-${lastSearchTerm}`"
            :columns="columns"
            :data="searchResults"
            :totalRecords="totalSearchRecords"
            :hasPagination="true"
            :currentPage="paginationOptions.currentPage"
            :totalPages="paginationOptions.totalPages"
            :initialSortOptions="sortOptions"
            :noDataMessage="
              lastSearchTerm
                ? `No results for '${lastSearchTerm}'`
                : 'Start a search'
            "
            @onSortChanged="onTableSortChanged"
            @onPageChanged="onTablePageChanged"
            @onRowClicked="onRowClicked"
            @onFilterChanged="onFilterChanged"
          >
            <template v-slot:isLoanUnit="props">
              <svg
                :class="
                  `stroke-current h-5 w-5 ${
                    props.row.is_loan_unit ? 'text-green-700' : 'text-red-700'
                  }`
                "
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
              >
                <path v-if="props.row.is_loan_unit" d="M20 6L9 17l-5-5" />
                <path v-else d="M18 6 6 18M6 6l12 12" />
              </svg>
            </template>
            <template v-slot:isOperational="props">
              <svg
                :class="
                  `stroke-current h-5 w-5 ${
                    props.row.is_operational ? 'text-green-700' : 'text-red-700'
                  }`
                "
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
              >
                <path v-if="props.row.is_operational" d="M20 6L9 17l-5-5" />
                <path v-else d="M18 6 6 18M6 6l12 12" />
              </svg>
            </template>
          </Table>
        </div>
      </div>
    </main>
    <!-- <portal to="overlay-outlet">
      <Panel
        :showing="showingUploadDocumentPanel"
        @close="closePanel"
        :title="editingDoc.document_id ? 'Edit Document' : 'Upload Document'"
      >
        <UploadPublishedDoc :document="editingDoc" @complete="onComplete" />
      </Panel>
    </portal> -->
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Alert = () => import("@/components/Alert.vue");
const Table = () => import("@/components/Table.vue");
const Spinner = () => import("@/components/Spinner.vue");
const Toggle = () => import("@/components/Toggle.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const UploadPublishedDoc = () =>
  import("@/components/Documents/UploadPublishedDoc.vue");
const Panel = () => import("@/components/Panel.vue");
const ViewDocument = () => import("@/components/ViewDocument.vue");

export default {
  name: "EquipmentIndex",
  components: {
    PageHeader,
    Alert,
    Table,
    Spinner,
    Toggle,
    ConfirmAlert,
    UploadPublishedDoc,
    Panel,
    ViewDocument,
  },
  data() {
    return {
      viewState: "Idle",
      searchTerm: "",
      lastSearchTerm: "",
      currentTab: "all",
      equipment: [],
      searchResults: [],
      paginationOptions: {
        currentPage: 1,
        totalPages: 1,
      },
      filterOptions: {},
      searchFilterOptions: {},
      totalRecords: 0,
      totalSearchRecords: 0,
      sortOptions: {
        field: "created_at",
        isAsc: false,
        pageSize: 50,
      },
      columns: [
        {
          title: "Product Code",
          field: "productCode",
          selector: (row) => row.product_code,
          filter: {
            lookup: {
              searchFn: this.ProductService.lookupProductByProductCode,
              idAccessor: (product) => product.product_code,
              labelAccessor: (product) => `${product.product_code} (${product.name})`,
            },
          },
          //   widthClass: "w-64",
        },
        {
          title: "Product Description",
          field: "name",
          selector: (row) => row.name,
          filter: {
            lookup: {
              searchFn: this.ProductService.lookupProduct,
              idAccessor: (product) => product.name,
              labelAccessor: (product) => product.name,
            },
          },
        },
        {
          title: "Company / Contact",
          field: "ownerId",
          selector: (row) => row.owner ? row.owner.name : "",
          filter: {
            lookup: {
              searchFn: this.ContactService.lookupContact,
              idAccessor: (contact) => contact.contact_id,
              labelAccessor: (contact) => contact.name,
            },
          },
        },
        {
          title: "Serial Number",
          field: "serialNumber",
          selector: (row) => row.serial_number,
        },
        {
          title: "Operational?",
          field: "isOperational",
          type: "custom",
          filter: {
            options: [
              {
                label: "Yes",
                value: "Y",
              },
              {
                label: "No",
                value: "N",
              },
            ],
          },
        },
        {
          title: "Loan Unit?",
          field: "isLoanUnit",
          type: "custom",
          filter: {
            options: [
              {
                label: "Yes",
                value: "Y",
              },
              {
                label: "No",
                value: "N",
              },
            ],
          },
        },
      ],
    };
  },
  async mounted() {
    try {
      this.searchTerm = this.$store.state.equipmentSearchTerm;

      this.currentTab = this.searchTerm ? "search" : "all";

      this.viewState = "Loading";

      this.currentTab == "all"
        ? await this.loadEquipment()
        : await this.performSearch(this.searchTerm);
      this.viewState = "Idle";
    } catch (error) {
      console.error(error);
      this.viewState = "Error";
      this.error = error;
    }
  },
  methods: {
    viewAllTab: async function() {
      this.$nextTick(() => {
        this.currentTab = "all";
      });
      if (this.equipment.length == 0) await this.loadEquipment();
      this.$store.dispatch("setEquipmentSearchTerm", "");
    },
    loadEquipment: async function() {
      this.viewState = "Loading";
      try {
        let result = await this.EquipmentService.getEquipment({
          ...this.paginationOptions,
          ...this.sortOptions,
          filterOptions: this.filterOptions,
        });
        console.log(result);
        this.equipment = result.data;
        this.paginationOptions.currentPage = result.page;
        this.paginationOptions.totalPages = result.page_count;
        this.totalRecords = result.count;
      } catch (error) {
        console.error(error);
      } finally {
        this.viewState = "Idle";
      }
    },
    onRowClicked: function(row) {
      this.$router.push({
        name: "equipment-detail",
        params: { equipmentId: row.equipment_id },
      });
    },
    onTableSortChanged: async function(sortOptions) {
      this.sortOptions = sortOptions;
      this.searchTerm != ""
        ? await this.performSearch(this.lastSearchTerm)
        : await this.loadEquipment();
    },
    onTablePageChanged: async function(direction) {
      this.paginationOptions.currentPage =
        this.paginationOptions.currentPage + direction;
      this.searchTerm != ""
        ? await this.performSearch(this.lastSearchTerm)
        : await this.loadEquipment();
    },
    onFilterChanged: async function(filter) {
      console.log(filter);
      console.log("this.currentTab", this.currentTab);
      if (this.currentTab == "search") {
        console.log("setting search");
        this.searchFilterOptions = filter;
        await this.performSearch(this.lastSearchTerm);
      } else {
        console.log("setting all");
        this.filterOptions = filter;
        await this.loadEquipment();
      }
    },
    submitSearchForm: function() {
      this.searchFilterOptions = {};
      this.performSearch(this.searchTerm);
    },
    performSearch: async function(searchTerm) {
      if (searchTerm != "") {
        this.viewState = "Loading";

        this.currentTab = "search";

        this.$store.dispatch("setEquipmentSearchTerm", searchTerm);
        try {
          let result = await this.EquipmentService.searchEquipment(searchTerm, {
            ...this.paginationOptions,
            ...this.sortOptions,
            filterOptions: this.searchFilterOptions,
          });
          console.log(result);
          this.searchResults = result.data;
          this.paginationOptions.currentPage = result.page;
          this.paginationOptions.totalPages = result.page_count;
          this.totalSearchRecords = result.count;
          this.lastSearchTerm = searchTerm;
          this.searchTerm = "";
        } catch (error) {
          console.error(error);
        } finally {
          this.viewState = "Idle";
        }
      }
    },
  },
};
</script>
